import React, { Component, useState, useEffect} from 'react'
import { Map } from '@esri/react-arcgis';
import { loadModules } from '@esri/react-arcgis';

import './BridgeMap.scss';



const BermudaTriangle = (props) => {

    const [graphic, setGraphic] = useState(null);
    useEffect(() => {

        loadModules(['esri/Graphic']).then(([Graphic]) => {
            // Create a polygon geometry
            const polygon = {
                type: "polygon", // autocasts as new Polygon()
                rings: [
                    [ 4926.20319110381],
                    [63329.3185135292]
                ]

            };

            // Create a symbol for rendering the graphic
            const fillSymbol = {
                type: "simple-fill", // autocasts as new SimpleFillSymbol()
                color: [227, 139, 79, 0.8],
                outline: { // autocasts as new SimpleLineSymbol()
                    color: [255, 255, 255],
                    width: 1
                }
            };

            // Add the geometry and symbol to a new graphic
            const graphic = new Graphic({
                geometry: polygon,
                symbol: fillSymbol
            });
            setGraphic(graphic);
            props.view.graphics.add(graphic);
        }).catch((err) => console.error(err));

        return function cleanup() {
            props.view.graphics.remove(graphic);
        };
    }, []);

    return null;

}


export default class BridgeMap extends Component {
  render() {
    return (
      <div className="map">
        <Map 
        mapProperties={{ basemap: 'dark-gray-vector' }}
        viewProperties={{
            center: [-122.668728, 45.518945],
            zoom: 13.5
        }}>
        <BermudaTriangle />
        </Map>
      </div>
    );
  }
}
import React, { Component } from "react";
import "./BridgeList.scss";
import TwitterLogo from "../../assets/images/twitter.svg";

export default class BridgeList extends Component {
  state = {
    bridges: [],
    hasLoaded: false,
    theme: localStorage.getItem("currentTheme")
      ? localStorage.getItem("currentTheme")
      : "dark"
  };

  render() {
    let listTheme = this.state.theme
      ? `theme-${this.state.theme}`
      : "theme-dark";
    return (
      <div className={`bridge-list-container`}>
        <h1 className="bridge-list-title">Portland Bridge Lifts</h1>

        <div className="site-footer">
          Unfortunately, the API we relied on to provide bridge lift data has
          been decommissioned by Multnomah County.
          <br />
          <br />
          There is hope it will be restored sometime in the future, and we hope
          to be back soon!
          <br />
          <br />
          <a
            href="https://twitter.com/pdxbridgelifts"
            rel="noopener noreferrer"
            target="blank"
            className="site-footer-social-logo"
          >
            <img src={TwitterLogo} alt="Twitter" />
            Follow Portland Bridge Lifts for updates
          </a>
          <div className="site-footer-text">
            This resource is in BETA. Data (was) provided by{" "}
            <a
              href="https://multco.us/bridges"
              rel="noopener noreferrer"
              target="blank"
            >
              Multnomah County Bridges
            </a>. Not responsible for data inaccuracies.{" "}
            <a href="mailto:contact@portlandbridgelifts.com?subject=Portland Bridge Lifts Feedback">
              Contact / Feedback
            </a>{" "}
            © {new Date().getFullYear()}
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet';
export default class AppHeader extends Component {
  render () {
    return (
      <Fragment>

      <link href="https://fonts.googleapis.com/css?family=Rubik:300,400,400i,500,700&display=swap" rel="stylesheet"></link>
      <link rel="stylesheet" href="https://js.arcgis.com/4.10/esri/css/main.css"></link>
      <Helmet
        title={`Portland Bridge Lifts Across the Willamette River in Portland, Oregon`}
        bodyAttributes={{ class: `` }}
        meta={[
          { name: 'description', content: `Portland Bridge Lifts in Portland, Oregon. Find out when the Broadway, Burnside, Morrison, and Hawthorne bridges across the Willamette River are up with the Portland Bridge Lift App.` },
          { name: 'keywords', content: `Portland, Bridge, Lifts, Schedule,  Bridges, Willamette, River, Boats, Up, Down, Oregon, Morrison, Hawthorne, Broadway, Steel, Burnside, Multnomah, App, Application` },
          { name: 'viewport', content: 'width=device-width, initial-scale=1' }
        ]}
        >
         <link rel="canonical" href="https://portlandbridgelifts.com" />
        <html lang='en' />
      </Helmet>
      </Fragment>
    );
  }
}

import React, { Component, Fragment } from 'react';
import './App.css';
import BridgeMap from './components/BridgeMap/BridgeMap'
import BridgeList from './components/BridgeList/BridgeList'
import AppHeader from './components/AppHeader/AppHeader'
import ReactGA from 'react-ga';

ReactGA.initialize(`UA-142188556-1`);

export default class App extends Component {
  componentDidMount  = () => ReactGA.pageview(window.location.pathname + window.location.search);
  componentDidUpdate = () => ReactGA.pageview(window.location.pathname + window.location.search);
  render() {
  
  return (
    <Fragment>
    <AppHeader />
    <div className="App">
      <BridgeList />
    </div>
    </Fragment>
  );
}
}
